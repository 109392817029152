import React from 'react';
import {graphql} from 'gatsby';
import Layout from '../components/layout';
import SliceZone from '../components/SliceZone';

export const HomePage = ({data}) => {
    if (!data) return null;
    const header = data.prismicHeaderitem || {};
    const footer = data.prismicFooteritem || {};
    const {lang, type, url} = header || {};
    const alternateLanguages = header.alternate_languages || [];
    const activeDoc = {
        lang,
        type,
        url,
        alternateLanguages,
    };

    const homepage = data.prismicHomepage || {};

    return (
        <Layout activeDocMeta={activeDoc} body={header} bodyFooter={footer} fullPage={true}>
            <SliceZone slices={homepage.data.body} />
        </Layout>
    );
};

export const query = graphql`
    query homepageQuery($lang: String) {
        prismicHomepage(lang: {eq: $lang}) {
            lang
            url
            data {
                body {
                    ... on PrismicHomepageBodyHeroSection {
                        id
                        primary {
                            button {
                                text
                            }
                            button_link {
                                url
                            }
                            subtitle {
                                raw
                            }
                            title {
                                text
                            }
                            background_image {
                                url
                            }
                            pulse_animation
                        }
                        internal {
                            content
                            description
                            ignoreType
                            mediaType
                        }
                        slice_type
                        slice_label
                    }
                    ... on PrismicHomepageBodyLargeTestimonialSection {
                        id
                        primary {
                            title1 {
                                text
                            }
                            text2 {
                                text
                            }
                            text1 {
                                text
                            }
                            section_number {
                                text
                            }
                            section_name {
                                text
                            }
                            section_image {
                                url
                            }
                            button1 {
                                text
                            }
                            button_link {
                                url
                            }
                            text_button {
                                text
                            }
                        }
                        internal {
                            content
                            description
                            ignoreType
                            mediaType
                        }
                        slice_type
                        slice_label
                    }
                    ... on PrismicHomepageBodySmalltestimonialSection {
                        id
                        internal {
                            content
                            description
                            ignoreType
                            mediaType
                        }
                        primary {
                            image {
                                url
                                alt
                            }
                            reversed
                            section_name {
                                text
                            }
                            section_number {
                                text
                            }
                            text {
                                text
                            }
                            title {
                                text
                            }
                            buttontext {
                                text
                            }
                            button_link {
                                url
                            }
                            isbutton
                            person_name {
                                text
                            }
                            person_position {
                                text
                            }
                        }
                        slice_type
                    }
                    ... on PrismicHomepageBodyTextureSection {
                        id
                        slice_type
                        primary {
                            buttontext {
                                text
                            }
                            emailtext {
                                text
                            }
                            title {
                                text
                            }
                        }
                    }
                    ... on PrismicHomepageBodyPerformanceSection {
                        id
                        slice_type
                        slice_label
                        primary {
                            title2 {
                                text
                            }
                            title1 {
                                text
                            }
                            text2 {
                                text
                            }
                            text1 {
                                text
                            }
                            background {
                                url
                                alt
                            }
                            section_number {
                                text
                            }
                            chart1_title {
                                text
                            }
                            chart2_title {
                                text
                            }
                        }
                    }
                    ... on PrismicHomepageBodyPartnersSection {
                        id
                        slice_type
                        slice_label
                        primary {
                            text {
                                text
                            }
                            section_number {
                                text
                            }
                            section_name {
                                text
                            }
                        }
                    }
                    ... on PrismicHomepageBodySolutionsSection {
                        id
                        slice_type
                        slice_label
                        primary {
                            title3 {
                                text
                            }
                            title2 {
                                text
                            }
                            title1 {
                                text
                            }
                            text3 {
                                text
                            }
                            text2 {
                                text
                            }
                            text1 {
                                text
                            }
                            section_number {
                                text
                            }
                            background {
                                url
                                alt
                            }
                            button {
                                text
                            }
                            button_link {
                                url
                            }
                            section_name {
                                text
                            }
                        }
                    }
                    ... on PrismicHomepageBodyCrisisSection {
                        id
                        slice_type
                        slice_label
                        primary {
                            section_number {
                                text
                            }
                            section_name {
                                text
                            }
                        }
                        items {
                            content_text {
                                text
                            }
                            content_title {
                                text
                            }
                            tab_title {
                                text
                            }
                            detail_2_title {
                                text
                            }
                            detail_2_performance_2 {
                                text
                            }
                            detail_2_performance_1 {
                                text
                            }
                            detail_2_name_2 {
                                raw
                            }
                            detail_2_name_1 {
                                raw
                            }
                            detail_1_title {
                                text
                            }
                            detail_1_performance_2 {
                                text
                            }
                            detail_1_performance_1 {
                                text
                            }
                            detail_1_name_2 {
                                raw
                            }
                            detail_1_name_1 {
                                raw
                            }
                            detail_3_title {
                                text
                            }
                            detail_3_performance_2 {
                                text
                            }
                            detail_3_performance_1 {
                                text
                            }
                            detail_3_name_2 {
                                raw
                            }
                            detail_3_name_1 {
                                raw
                            }
                        }
                    }
                    ... on PrismicHomepageBodyReferenceSection {
                        id
                        slice_type
                        slice_label
                        primary {
                            section_number {
                                text
                            }
                            section_name {
                                text
                            }
                        }
                        items {
                            text {
                                text
                            }
                            person_position {
                                raw
                            }
                            person_name {
                                text
                            }
                            image {
                                url
                            }
                            reference_detail_text_1 {
                                raw
                            }
                            reference_detail_list_1 {
                                raw
                            }
                            reference_detail_list_2 {
                                raw
                            }
                            reference_detail_heading_2 {
                                text
                            }
                            reference_detail_heading_1 {
                                text
                            }
                        }
                    }
                }
            }
        }
        prismicHeaderitem(lang: {eq: $lang}) {
            lang
            type
            url
            alternate_languages {
                lang
                type
            }
            data {
                button {
                    text
                }
                item1 {
                    text
                }
                item2 {
                    text
                }
                item3 {
                    text
                }
                item4 {
                    text
                }
                item5 {
                    text
                }
                item6 {
                    text
                }
                solutions {
                    solution_name {
                        text
                    }
                }

                cookie_consent {
                    raw
                }
            }
        }
        prismicFooteritem(lang: {eq: $lang}) {
            url
            type
            lang
            alternate_languages {
                lang
                type
            }
            data {
                about {
                    text
                }
                allrights {
                    text
                }
                item1 {
                    text
                }
                item2 {
                    text
                }
                item3 {
                    text
                }
                item4 {
                    text
                }
                item5 {
                    text
                }
                item6 {
                    text
                }
                item7 {
                    text
                }
                privacy {
                    text
                }
                terms {
                    text
                }
                clientinformation {
                    text
                }
                impressum {
                    text
                }
            }
        }
    }
`;

export default HomePage;
